import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import { getUsers } from "../firebase/users"
import { useEffect } from "react"
import { firebase, auth } from "../firebase/fb"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import ComingSoon from "../components/ComingSoon"

export default function Home({ data }) {
  useEffect(() => {
    getUsers()
  }, [])

  const firebaseUIAuthConfig = {
    signInSuccessUrl: "/",
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
    tosUrl: "/about",
    privacyPolicyUrl: "/contact",
  }

  return <ComingSoon />
  return (
    <Layout>
      <div>
        <StyledFirebaseAuth
          uiConfig={firebaseUIAuthConfig}
          firebaseAuth={auth}
        />

        <h4>{data.allMdx.totalCount} Posts</h4>
        {data.allMdx.nodes.map(({ id, fields, frontmatter, excerpt }) => (
          <div key={id}>
            <Link to={fields.slug}>
              {!!frontmatter.cover ? (
                <GatsbyImage
                  image={frontmatter.cover.childImageSharp.gatsbyImageData}
                />
              ) : null}
              <h3>
                {frontmatter.title}
                <span>— {frontmatter.date}</span>
              </h3>
              <p>{excerpt}</p>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
          coverCredit
          cover {
            childImageSharp {
              gatsbyImageData(width: 200)
            }
            publicURL
          }
        }
        fields {
          slug
        }
      }
      totalCount
    }
  }
`
