import React from "react"

const ComingSoon = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <h1 className="text-gray-800 text-4xl">Coming soon...</h1>
    </div>
  )
}

export default ComingSoon
